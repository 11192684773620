const Documents = () => {
	return [
		{
			url: 'D19370403_Beckx louisa joanna Dupont jacques hippolyte acte de mariage Paris 18.jpg',
			lastName: 'Beckx',
			firstName: 'Louisa Joanna',
			type: 'marriage',
			year: '1937/04/03',
			location: 'Paris 18'
		},
		{
			url: 'D19370403_Dupont jacques hippolyte Beckx louisa joanna acte de mariage Paris 18.jpg',
			lastName: 'Dupont',
			firstName: 'Jacques Adolphe',
			type: 'marriage',
			year: '1937/04/03',
			location: 'Paris 18'
		},
		{
			url: 'D19320630_Beckx louisa johanna acte photo Bruxelles 99.jpg',
			lastName: 'Beckx',
			firstName: 'Louisa Joanna',
			type: 'simple_photo',
			year: '1932/06/10',
			location: 'Bruxelles 99'
		},
		{
			url: 'D19360815_Beckx louisa. acte photo Bruxelles 99.jpg',
			lastName: 'Beckx',
			firstName: 'Louisa Joanna',
			type: 'simple_photo',
			year: '1932/08/15',
			location: 'Bruxelles 99'
		},
		{
			url: 'D19490630_Dupont jacques Beckx louisa acte photo.jpg',
			lastName: 'Dupont Beckx',
			firstName: 'Jacques Adolphe Louisa Joanna',
			type: 'simple_photo',
			year: '1949/06/30',
			location: ' '
		},
		{
			url: 'D19550930_Dupont pierre christiane Beckx louisa Saint Etienne 42.jpg',
			lastName: 'Dupont Beckx',
			firstName: 'Pierre Christiane Louisa',
			type: 'simple_photo',
			year: '1955/09/30'
		},
		{
			url: 'D19620321_Dupont pierre jacques Beckx louisa Vallee claudine georges Basset marcelle acte photo mariage de pierre claudine Troyes 10.jpg',
			lastName: 'Dupont Beckx Vallée Basset mariage Pierre Claudine',
			firstName: 'Jacques Pierre Louisa Claudine Georges Marcelle',
			type: 'marriage_photo',
			year: '1962/03/21',
			location: 'Saint Etienne 42'
		},
		{
			url: 'D19720730_Dupont jacques Beckx louisa Flowers marylin Redondo Beach USA 99.jpg',
			lastName: 'Dupont Beckx Flowers',
			firstName: 'Jacques Louisa Marilyn',
			type: 'vacation_photo',
			year: '1972/07/30',
			location: 'Redondo Beach USA 99'
		}
	]
}

export default Documents
