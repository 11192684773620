import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import './ActsTable.css'

import Header from '../../components/Header/Header'
import ActLink from './ActLink'

import capeyDocuments from '../../data/capeyDocuments'
import dupontDocuments from '../../data/dupontDocuments'
import fontaineDocuments from '../../data/fontaineDocuments'
import gervaiseauDocuments from '../../data/gervaiseauDocuments'
import salvageDocuments from '../../data/salvageDocuments'
import urbainDocuments from '../../data/urbainDocuments'
import toutardDocuments from '../../data/toutardDocuments'
import coulmeauDocuments from '../../data/coulmeauDocuments'
import potutDocuments from '../../data/potutDocuments'
import beckxDocuments from '../../data/beckxDocuments'

class ActsTable extends React.Component {
	state = {
		familyId: '',
		familyName: '',
		acts: []
	}

	componentDidMount() {
		this.baseState = this.state
		this.initComponent()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.familyId !== this.props.match.params.familyId) {
			this.initComponent()
		}
	}

	initComponent() {
		this.setState(this.baseState)

		this.setState({ familyId: this.props.match.params.familyId }, () => {
			this.getActs()
		})

		this.setState({
			familyName:
				this.props.match.params.familyId.charAt(0).toUpperCase() +
				this.props.match.params.familyId.slice(1)
		})
	}

	getActs(orderType = 'lastName', order = '+') {
		let acts = []

		if (this.state.familyId === 'capey') acts = capeyDocuments()
		else if (this.state.familyId === 'dupont') acts = dupontDocuments()
		else if (this.state.familyId === 'fontaine') acts = fontaineDocuments()
		else if (this.state.familyId === 'gervaiseau') acts = gervaiseauDocuments()
		else if (this.state.familyId === 'salvage') acts = salvageDocuments()
		else if (this.state.familyId === 'urbain') acts = urbainDocuments()
		else if (this.state.familyId === 'toutard') acts = toutardDocuments()
		else if (this.state.familyId === 'coulmeau') acts = coulmeauDocuments()
		else if (this.state.familyId === 'potut') acts = potutDocuments()
		else if (this.state.familyId === 'beckx') acts = beckxDocuments()

		acts.map(act => {
			let actName
			if (act.type === 'birth') actName = 'Acte de Naissance'
			else if (act.type === 'birth_extrait_minutes')
				actName = 'Acte de Naissance Extrait Minutes'
			else if (act.type === 'death') actName = 'Acte de Décès'
			else if (act.type === 'marriage') actName = 'Acte de Mariage'
			else if (act.type === 'marriage_extrait')
				actName = 'Acte de Mariage Extrait Minutes'
			else if (act.type === 'catholic_marriage')
				actName = 'Acte de Mariage Catholique'
			else if (act.type === 'marriage_publication')
				actName = 'Publication Mariage'
			else if (act.type === 'bans_marriage')
				actName = 'Publication Bans Mariage'
			else if (act.type === 'family') actName = 'Livret de Famille'
			else if (act.type === 'military') actName = 'Livret Militaire'
			else if (act.type === 'soldier_military') actName = 'Militaire'
			else if (act.type === 'military_certificat')
				actName = 'Certificat Aptitude Armée'
			else if (act.type === 'military_free')
				actName = 'Livret Militaire Libération Armée'
			else if (act.type === 'hommage_nation') actName = 'Hommage de la Nation'
			else if (act.type === 'photo') actName = 'Photo'
			else if (act.type === 'baptism') actName = 'Acte de Baptême'
			else if (act.type === 'inheritage') actName = 'Acte de Succession'
			else if (act.type === 'fiche') actName = 'Fiche'
			else if (act.type === 'census') actName = 'Recensement'
			else if (act.type === 'driving_license') actName = 'Permis de conduire'
			else if (act.type === 'driving_license_int')
				actName = 'Permis de conduire international'
			else if (act.type === 'passport') actName = 'Passeport'
			else if (act.type === 'rapatriement')
				actName = 'Certificat de rapatriement'
			else if (act.type === 'medal_bronze') actName = 'Médaille bronze'
			else if (act.type === 'medal_bronze_compta')
				actName = 'Médaille de bronze comptabilité'
			else if (act.type === 'aptitude_enseigner')
				actName = 'Aptitude a enseigner'
			else if (act.type === 'convocation_correction_comptable')
				actName = 'Convocation pour correction BP comptable'
			else if (act.type === 'test_aptitude_armee')
				actName = 'Convocation pour tests aptitude armée'
			else if (act.type === 'aptitude_armee_sursis_etude')
				actName = "Courrier d'aptitude armée et sursis pour études"
			else if (act.type === 'courrier_sursis') actName = 'Courrier pour sursis'
			else if (act.type === 'demande_resiliation_sursis')
				actName = 'Réponse à demande de résiliation sursis'
			else if (act.type === 'resiliation_sursis')
				actName = 'Résiliation du sursis'
			else if (act.type === 'courrier_notif_incorporation')
				actName = "Courrier de notification d'incorporation"
			else if (act.type === 'certificat_transmission')
				actName = 'Certificat de transmission'
			else if (act.type === 'military_individual')
				actName = 'Livret militaire individuel'
			else if (act.type === 'demande_resiliation_sursis')
				actName = 'Bon de transport pour permission'
			else if (act.type === 'affectation_disponible')
				actName = "Avis d'affectation de disponible"
			else if (act.type === 'bonne_conduite_armee')
				actName = 'Certificat de bonne conduite armée'
			else if (act.type === 'military_foyer')
				actName = 'Livret militaire renvoyé dans ses foyers'
			else if (act.type === 'mobilisation')
				actName = 'Fascicule de mobilisation'
			else if (act.type === 'medal_work_vermeil')
				actName = "médaille d'honneur du travail Vermeil"
			else if (act.type === 'medal_work_gold')
				actName = "Médaille d'honneur du travail Or"
			else if (act.type === 'will') actName = 'Testament'
			else if (act.type === 'war_versailles')
				actName = 'Conseils de guerre de Versailles'
			else if (act.type === 'judge_military')
				actName = 'Jugement juridiction militaire conseil de guerre'
			else if (act.type === 'aptitude_enseigner_maths_fi')
				actName = 'Aptitude Enseigner Maths Financières'
			else if (act.type === 'admission_ecole_diderot')
				actName = 'Admission Ecole Diderot'
			else if (act.type === 'admission_examen_preliminaire')
				actName = 'Admission Examen Préliminaire'
			else if (
				act.type === 'reussite_examen_preliminaire_diplome_expert_comptable'
			)
				actName = 'Réussite Examen Préliminaire Diplôme Expert Comptable'
			else if (act.type === 'felicitations_comme_prof')
				actName = 'Félicitations comme prof'
			else if (act.type === 'convocation_correcteur_bp_comptable')
				actName = 'Convocation Correcteur BP comptable'
			else if (act.type === 'notification_sursis')
				actName = 'Notification Sursis'
			else if (act.type === 'diplome_transmission')
				actName = 'Diplôme Transmission'
			else if (act.type === 'bon_transport_pour_permission')
				actName = 'Bon Transport Pour Permission'
			else if (act.type === 'convocation_conseil_de_famille')
				actName = 'Convocation conseil de famille'
			else if (act.type === 'jugement_notification_pupille_de_la_nation')
				actName = 'Jugement notification pupille de la nation'
			else if (act.type === 'communion_solennelle')
				actName = 'Communion Solennelle'
			else if (act.type === 'confirmation') actName = 'Acte de Confirmation'
			else if (act.type === 'engagement') actName = 'fiançailles'
			else if (act.type === 'certificat_de_secouriste')
				actName = 'Certificat de secouriste'
			else if (act.type === 'medaille_militaire') actName = 'Médaille militaire'
			else if (act.type === 'medaille_militaire-et-croix_de_guerre')
				actName = 'Medaille militaire et Croix de guerre'
			else if (act.type === 'carte_electeur') actName = "Carte d'électeur"
			else if (act.type === 'first_communion') actName = '1ère communion'
			else if (act.type === 'pension_veuve_de_guerre')
				actName = 'Pension veuve de guerre'
			else if (act.type === 'brevet_pension_veuve_de_guerre')
				actName = 'Brevet pension veuve de guerre'
			else if (act.type === 'slip') actName = 'Bordereau'
			else if (act.type === 'newspaper') actName = 'Journal'
			else if (act.type === 'simple_photo') actName = 'Photo/vidéo/Audio'
			else if (act.type === 'marriage_photo') actName = 'Photo de mariage'
			else if (act.type === 'communion_solennelle_photo')
				actName = 'Photo Communion Solennelle'
			else if (act.type === 'confirmation_photo')
				actName = 'Photo communion solennelle'
			else if (act.type === 'solennelle_photo')
				actName = 'Photo communion solennelle'
			else if (act.type === 'birth_photo') actName = 'Photo de naissance'
			else if (act.type === 'batism_photo') actName = 'Photo baptême'
			else if (act.type === 'engagement_photo') actName = 'Photo fiançailles'
			else if (act.type === 'birthday_photo') actName = 'Photo anniversaire'
			else if (act.type === 'summer_camp_photo') actName = 'Photo colonie'
			else if (act.type === 'vacation_photo') actName = 'Photo vacances'
			else if (act.type === 'school_photo') actName = 'Photo école'
			else if (act.type === 'baptism_photo') actName = 'Photo de baptême'
			else if (act.type === 'identite_photo') actName = "Photo d'identité"
			else if (act.type === 'soldier_military_photo')
				actName = 'Photo Militaire'
			else if (act.type === 'simple_soldier_military_photo')
				actName = 'Photo Militaire et autre'
			else if (act.type === 'photo') actName = 'Photo'
			else if (act.type === 'inhumation_cimetiere')
				actName = 'Acte inhumation cimetière'
			else if (act.typeExtra === 'livret') actName = actName + ' livret'
			else if (act.typeExtra === 'copy') actName = actName + ' copie'
			else if (act.typeExtra === 'extrait_minutes')
				actName = actName + ' extrait minutes'
			else if (act.typeExtra === 'extrait_registre')
				actName = actName + ' extrait registres'
			else if (act.typeExtra === 'extrait') actName = actName + ' extrait'
			else if (act.typeExtra === 'registre') actName = actName + ' registre'
			else if (act.typeExtra === 'catholic') actName = actName + ' catholique'
			else if (act.typeExtra === 'nuptials') actName = actName + ' seconde noce'
			else if (act.typeExtra === 'publication')
				actName = actName + ' 1ère publication'
			else if (act.typeExtra === 'page1') actName = actName + ' page 1'
			else if (act.typeExtra === 'page2') actName = actName + ' page 2'
			else if (act.typeExtra === 'v1') actName = actName + ' v1'
			else if (act.typeExtra === 'v2') actName = actName + ' v2'
			else if (act.typeExtra === 'table_decade')
				actName = actName + ' tables décennales'
			else if (act.typeExtra === 'copie_integrale')
				actName = actName + ' copie intégrale'
			else if (act.typeExtra === 'extrait_minutes_changement_prenom')
				actName = actName + ' extrait minutes changement prénom'
			else if (act.typeExtra === 'jugement_tribunal_changement_prenom')
				actName = actName + ' Jugement tribunal changement prénom'
			else if (act.typeExtra === 'extrait_ordre_du_regiment_liberable')
				actName = actName + ' Extrait ordre du régiment libérable'
			else if (act.typeExtra === 'certificat_bonne_conduite')
				actName = actName + ' Certificat bonne conduite'
			else if (act.typeExtra === 'faire_part_benediction_nuptiale')
				actName = actName + ' Faire part bénédiction nuptiale'
			else if (act.typeExtra === 'feuille_de_route')
				actName = actName + ' Feuille de route'
			else if (act.typeExtra === 'faire_part_deces')
				actName = actName + ' Faire part de décès'
			else if (act.typeExtra === 'faire_part_obseques')
				actName = actName + ' Faire part obsèques'
			else if (act.typeExtra === 'table_decennales')
				actName = actName + ' Table décennales'
			else if (act.typeExtra === 'certificat_de_bonne_conduite')
				actName = actName + ' Certificat de bonne conduite'
			else if (act.typeExtra === 'registre_journalier_inhumation')
				actName = actName + " Registre jounalier d'inhumation"
			else if (act.typeExtra === 'registre_annuel_inhumation')
				actName = actName + " Registre annuel d'inhumation"
			else if (act.typeExtra === 'gazette_tribuneaux_jugement')
				actName = actName + ' Gazette des tribuneaux jugement'
			else if (act.typeExtra === 'entree_au_bagne')
				actName = actName + ' Entrée au bagne'

			if (act.year) act.date = moment(act.year).format('DD MMMM YYYY')

			return (act.actName = actName)
		})

		let newActs = acts

		if (orderType === 'year') {
			newActs = acts.filter(act => act.year)
			const nodateActs = acts.filter(act => !act.year)

			newActs = newActs.sort((a, b) => {
				if (new Date(a[orderType]) < new Date(b[orderType]))
					return order === '+' ? -1 : 1
				if (new Date(a[orderType]) > new Date(b[orderType]))
					return order === '+' ? 1 : -1
				return 0
			})

			if (nodateActs) newActs = newActs.concat(nodateActs)
		} else if (orderType === 'lastName') {
			newActs = newActs.sort((a, b) => {
				if (new Date(a['year']) < new Date(b['year'])) return -1
				if (new Date(a['year']) > new Date(b['year'])) return 1
				return 0
			})

			newActs = newActs.sort((a, b) => {
				if (a['firstName'] < b['firstName']) return -1
				if (a['firstName'] > b['firstName']) return 1
				return 0
			})

			newActs = newActs.sort((a, b) => {
				if (a['lastName'] < b['lastName']) return order === '+' ? -1 : 1
				if (a['lastName'] > b['lastName']) return order === '+' ? 1 : -1
				return 0
			})
		} else if (orderType === 'firstName') {
			newActs = newActs.sort((a, b) => {
				if (a['lastName'] < b['lastName']) return -1
				if (a['lastName'] > b['lastName']) return 1
				return 0
			})

			newActs = newActs.sort((a, b) => {
				if (a['firstName'] < b['firstName']) return order === '+' ? -1 : 1
				if (a['firstName'] > b['firstName']) return order === '+' ? 1 : -1
				return 0
			})
		} else {
			newActs = newActs.sort((a, b) => {
				if (a[orderType] < b[orderType]) return order === '+' ? -1 : 1
				if (a[orderType] > b[orderType]) return order === '+' ? 1 : -1
				return 0
			})
		}

		const actRow = newActs.map((act, i) => {
			return (
				<tr key={i}>
					<td data-label="Nom">{act.lastName}</td>
					<td data-label="Prénoms">{act.firstName}</td>
					<td data-label="Type">{act.actName}</td>
					<td data-label="Date">{act.date}</td>
					<td data-label="Lieu">
						<Link
							to={{
								pathname: `/famille/${this.state.familyId}/documents/${act.location}`
							}}
							target="_blank"
						>
							{act.location}
						</Link>
					</td>
					<td data-label="Voir" className="text-center">
						<ActLink
							path={`${act.family || this.state.familyId}/documents`}
							url={act.url}
						/>
					</td>
				</tr>
			)
		})

		this.setState({ acts: actRow })
	}

	render() {
		if (!this.props.match) return <div />
		else
			return (
				<div>
					<Header
						title={`Famille ${this.state.familyName}<br /><i><small>Actes Civils - Administratifs - Religieux - Photos</small></i>`}
					/>

					<div className="table-container">
						<table className="ui celled striped unstackable table">
							<thead className={`table-header table-${this.state.familyId}`}>
								<tr>
									<th>
										Nom
										<div className="right aligned button-order">
											<i
												className="arrow alternate circle up outline icon"
												onClick={() => this.getActs('lastName', '+')}
											></i>
											<i
												className="arrow alternate circle down outline icon"
												onClick={() => this.getActs('lastName', '-')}
											></i>
										</div>
									</th>
									<th>
										Prénoms
										<div className="right aligned button-order">
											<i
												className="arrow alternate circle up outline icon"
												onClick={() => this.getActs('firstName', '+')}
											></i>
											<i
												className="arrow alternate circle down outline icon"
												onClick={() => this.getActs('firstName', '-')}
											></i>
										</div>
									</th>
									<th>
										Type
										<div className="right aligned button-order">
											<i
												className="arrow alternate circle up outline icon"
												onClick={() => this.getActs('actName', '+')}
											></i>
											<i
												className="arrow alternate circle down outline icon"
												onClick={() => this.getActs('actName', '-')}
											></i>
										</div>
									</th>
									<th>
										Date
										<div className="right aligned button-order">
											<i
												className="arrow alternate circle up outline icon"
												onClick={() => this.getActs('year', '+')}
											></i>
											<i
												className="arrow alternate circle down outline icon"
												onClick={() => this.getActs('year', '-')}
											></i>
										</div>
									</th>
									<th>
										Lieu
										<div className="right aligned button-order">
											<i
												className="arrow alternate circle up outline icon"
												onClick={() => this.getActs('location', '+')}
											></i>
											<i
												className="arrow alternate circle down outline icon"
												onClick={() => this.getActs('location', '-')}
											></i>
										</div>
									</th>
									<th className="text-center">Voir</th>
								</tr>
							</thead>
							<tbody>{this.state.acts}</tbody>
						</table>
					</div>
				</div>
			)
	}
}

export default ActsTable
